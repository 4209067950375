import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '@gfs/shared-services';
import { RecipeImageUploadConfig } from '../photo-manager.model';
import { Observable, Subject, combineLatest, firstValueFrom, iif, of } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, first, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { CropModalComponent } from '../crop-modal/crop-modal.component';
import { ConfirmationModalComponent } from 'libs/shared-components/src/lib/modals/confirmation-modal/confirmation-modal.component';
import { ErrorModalComponent } from 'libs/shared-components/src/lib/modals/error-modal/error-modal.component';
import { InventoryConstants } from '@gfs/constants';


@Injectable({
    providedIn: 'root'
})
export class RecipeImageUploadBase64 {

    loadingOverlay: any;
    constructor(
        private config: RecipeImageUploadConfig,
        private appConfig: AppConfigService,
        private dialog: MatDialog,
    ) {
     }

    openAdhocFileSelect(onChange: ($event: Event) => any, index: number) {
        const anonInput = document.createElement('input');
        anonInput.setAttribute('type', 'file');
        anonInput.setAttribute('accept', this.config.allowedMimeTypes.join(', '));
        anonInput.onchange = onChange;

        // Testing framework not capable of articulating OS file dialog. Configmap will never provide a value on this key => default false
        const isAutomatedTest = !!this.appConfig.getSettings().FF_ENABLE_TEST_FRAMEWORK_FILE_UPLOAD;
        if (isAutomatedTest) {
            const placeholder = document.getElementsByTagName('app-photo-content-placeholder');
            this.append(placeholder, index, anonInput);
            return;
        }

        anonInput.click();
    }

    append(collection: HTMLCollectionOf<Element>, index: number, input: HTMLInputElement): void {
        collection[index].append(input);
    }

    getBase64Content(file: File): Observable<string> {
        const deferred = new Subject<string>();
        const reader = new FileReader();

        reader.onload = function () {
            deferred.next(reader.result as string);
            deferred.complete();
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

        reader.readAsDataURL(file);

        return deferred.asObservable();
    }

    cropImage(imageBase64: string): Observable<string> {
        return this.dialog
            .open(CropModalComponent, {
                data: { imageBase64 },
                disableClose: true
            })
            .afterClosed();
    }

    validateFile(file: File) {
        const isValidFileSize = this.config.maxSizeBytes === -1 || file.size <= this.config.maxSizeBytes;
        const hasValidMimeType = this.config.allowedMimeTypes.some(type => type === file.type);
        if(!(isValidFileSize && hasValidMimeType)){
             this.dialog.open(ErrorModalComponent, {
                id: InventoryConstants.ERROR_MODAL_ID,
                panelClass: 'error-modal',
                data:{
                    title:'Selected file should be less than 10MB And File types should be: .jpeg, .png',
                    subtitle:null,
                    cancelButtonLabel: 'OK'  
                }
              }).afterClosed();
        }
        return hasValidMimeType && isValidFileSize;
    }

}
